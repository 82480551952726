import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../state/store";
import { UsersService } from "../../services/users/Users";
import { navigate } from "gatsby";
import { toggleSearchBar } from "../../state/search/searchBarSlice";
import { GitPullRequest, Phone, Search, User } from "react-feather";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../buttons/LoadingSpinner";
import { SimcardService } from "../../services/simcards/Simcards";
import { setAside } from "../../state/component/AsideSlice";
import { setSelectedCompany } from "../../state/companies/CompaniesSlice";

const SearchBar = () => {
  const { t } = useTranslation();
  const service = new UsersService();
  const simService = new SimcardService();
  const isVisible = useSelector(
    (state: RootState) => state.searchBar.isVisible
  );
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const dispatch = useDispatch();
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchMode, setSearchMode] = useState<string[]>([]);
  const debounceTimeout = useRef<number | undefined>(undefined);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const selectBoxRef = useRef<HTMLDivElement>(null);

  const tagOptions = {
    label: <span>Options</span>,
    title: "options",
    options: [
      { label: "@user", value: "@user", searchLabel: "@user", key: "tag-user" },
      { label: "@sim", value: "@sim", searchLabel: "@sim", key: "tag-sim" },
      {
        label: "@impersonate",
        value: "@impersonate",
        searchLabel: "@impersonate",
        key: "tag-impersonate",
      },
    ],
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const dropdownElement = document.querySelector(
        ".ant-select-dropdown"
      ) as HTMLElement;
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target as Node) &&
        dropdownElement &&
        !dropdownElement.contains(event.target as Node)
      ) {
        dispatch(toggleSearchBar());
      }
    }

    if (isVisible) {
      setOptions([tagOptions]);
      document.addEventListener("mousedown", handleClickOutside);
      setTimeout(() => {
        (selectBoxRef.current as any)?.focus();
      }, 100);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      setOptions([]);
      setSearchMode([]);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, dispatch]);

  const fetchOptions = async (term: string) => {
    setOptionsLoading(true);
    const trimmedTerm = term.trim();

    if (trimmedTerm.length < 3) {
      setOptions([]);
      setOptionsLoading(false);
      return;
    }

    // const groupedOptions = [];
    const activeModes = searchMode.length > 0 ? searchMode : ["@user", "@sim"];
    const searchModes = [
      {
        mode: "@user",
        label: "Gebruikers",
        icon: <User size={16} />,
        fetchFunction: service.getUserNames,
      },
      {
        mode: "@sim",
        label: "Simkaarten",
        icon: <Phone size={16} />,
        // Implement simoptions call
        fetchFunction: simService.getSimOptions,
      },
      {
        mode: "@impersonate",
        label: "Impersonate",
        icon: <GitPullRequest size={16} />,
        // Implement impersonateoptions call
        fetchFunction: service.getImpersonateOptions,
      },
    ];

    const fetchPromises = [];

    for (const { mode, label, icon, fetchFunction } of searchModes) {
      if (activeModes.includes(mode) && fetchFunction) {
        console.log(`Queuing fetch for ${mode.toLowerCase()}...`);
        const fetchPromise = fetchFunction(companyId, trimmedTerm).then(
          (fetchedOptions) => {
            const options = fetchedOptions.map((option) => ({
              label: (
                <div className="flex justify-between items-center">
                  <p>{option.label}</p>
                  {icon}
                </div>
              ),
              value: `${label.toLowerCase()}-${option.value}-${option.label}`,
              searchLabel: option.label,
            }));

            if (options.length > 0) {
              return {
                label: <span>{label}</span>,
                title: label,
                options,
              };
            }
            return null;
          }
        );
        fetchPromises.push(fetchPromise);
      }
    }

    const groupedOptions = (await Promise.all(fetchPromises)).filter(Boolean);

    setOptions([tagOptions, ...groupedOptions]);
    setOptionsLoading(false);
  };

  const handleSearch = (term: string) => {
    if (term.startsWith("@")) {
      setOptions([tagOptions]);
      return;
    }
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = window.setTimeout(() => {
      fetchOptions(term);
    }, 500);
  };

  const handleSelect = (value: string) => {
    const selectedTag = tagOptions.options.find(
      (tag) => tag.searchLabel === value
    );

    if (selectedTag && !searchMode.includes(value)) {
      setSearchMode((prevModes) => [...prevModes, value]);
    } else if (value && !selectedTag) {
      navigateBasedOnMode(value);
    }
  };

  const handleDeselect = (value: string) => {
    if (value.startsWith("@")) {
      setSearchMode((prevModes) => prevModes.filter((mode) => mode !== value));
    }
  };

  const navigateBasedOnMode = async (value: string) => {
    const [category, id, label] = value.split("-");

    if (category === "impersonate") {
      dispatch(setSelectedCompany({ id: Number(id), companyName: label }));
    } else if (category === "simkaarten") {
      setOptionsLoading(true);
      const sim = await simService.getSimcard(Number(id), companyId);
      setOptionsLoading(false);
      dispatch(
        setAside({
          active: true,
          component: "simDetail",
          data: { sim: { key: 1, ...sim } },
          crumb: "Simkaart",
        })
      );
    } else if (category === "gebruikers") {
      navigate(`/user/dashboard/overview?user=${id}`);
    } else {
      console.warn("Unknown category:", category);
    }
    dispatch(toggleSearchBar());
  };

  return isVisible ? (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-40"
        onClick={() => dispatch(toggleSearchBar())}
      />
      <div
        className={`fixed top-0 md:top-10 left-1/2 transform -translate-x-1/2 z-[70] w-full md:w-[450px] bg-white dark:bg-slate-800 p-4 rounded-b-lg md:rounded-lg shadow-lg`}
        ref={searchBarRef}
      >
        <Select
          ref={selectBoxRef}
          mode="tags"
          autoFocus
          showSearch
          options={options}
          listHeight={500}
          placeholder={t("Zoeken...")}
          onSelect={handleSelect}
          onDeselect={handleDeselect}
          onSearch={handleSearch}
          loading={true}
          filterOption={(input, option) =>
            option?.searchLabel?.toLowerCase().includes(input.toLowerCase())
          }
          className="w-full"
          suffixIcon={
            <>
              {/* {optionsLoading && <LoadingSpinner />} */}
              <span>Ctrl+K</span>
              {optionsLoading ? <LoadingSpinner /> : <Search size={14} />}
            </>
          }
          notFoundContent={t("Geen resultaten")}
        />
      </div>
    </>
  ) : null;
};

export default SearchBar;
