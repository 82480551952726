import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { TarifService } from "../../services/simcards/Tarifs";
import CurrencyFormatter from "../../utils/text/CurrencyFormatter";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import RatePlanInfo from "../../components/typography/sim/RatePlanInfo";

const tarifService = new TarifService();

const SelectTarif = ({ onSelect, defaultValue, type }) => {
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const [tarifs, setTarifs] = useState([""]);

  useEffect(() => {
    tarifService.getTarifs(type, companyId).then((res) => {
      console.log(res);
      const result = Object.groupBy(res, ({ trf_type }) => trf_type);
      console.log(result);
      setTarifs(result);
    });
  }, []);

  return (
    <Select
      showSearch
      defaultValue={defaultValue}
      style={{ width: "100%" }}
      onChange={onSelect}
      optionFilterProp="label"
      optionLabelProp="searchLabel"
      id="5"
      options={Object.keys(tarifs).map((key) => ({
        label: (
          <span className="font-bold text-slate-950 dark:text-slate-200">
            {key}
          </span>
        ),
        title: key,
        options: Object.keys(tarifs[key]).map((trf) => ({
          label: (
            <div className="flex justify-between items-center">
              <div className="flex flex-col">
                <p>{tarifs[key][trf].trf_naam}</p>
              </div>
              {tarifs[key][trf].price ? (
                <div className="flex flex-col justify-end text-right">
                  <p>
                    <CurrencyFormatter price={tarifs[key][trf].price} />
                  </p>
                  <RatePlanInfo ratePlan={tarifs[key][trf]} />
                </div>
              ) : (
                ""
              )}
            </div>
          ),
          searchLabel: tarifs[key][trf].trf_naam,
          value: tarifs[key][trf].trf_id,
        })),
      }))}
    />
  );
};

export default SelectTarif;
