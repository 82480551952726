export enum UserRoles {
  ADMIN = "admin",
  USER = "user",
}

export enum CompanyModules {
  CYOD = "CYOD",
  TELECOM = "Telecom",
  RAPPORTAGE = "Rapportage",
  CALLCONNECT = "Call Connect",
  PROJECTEN = "Projecten",
  FLEXIO = "Flexio",
  BUDGETMANAGER = "Budget Manager",
  IT = "IT",
  HARDWARE = "Hardware",
}

export const hasRole = (
  roles: { role: string }[],
  roleToCheck: UserRoles
): boolean => {
  return roles.some((role) => role.role === roleToCheck);
};

export const hasModule = (
  modules: { mod_name: string }[],
  moduleToCheck: CompanyModules
): boolean => {
  return modules.some((module) => module.mod_name === moduleToCheck);
};
