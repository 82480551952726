import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import LoadingSpinner from "../../components/buttons/LoadingSpinner";
import SearchTable from "../../components/inputs/search/SearchTable";
import Table from "../../components/tables/Table";
import TableItem from "../../components/tables/TableItem";
import { Progress } from "antd";
import { LicenseService } from "../../services/licenses/Licenses";
import { UsersService } from "../../services/users/Users";

type License = {
  license: any;
};

const CurrentLicenses: FC<License> = ({ license }) => {
  const licenseState = useSelector(
    (state: RootState) => state.aside.data.license
  );
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const [search, setSearch] = useState("");
  const [filteredUserLicenses, setFilteredUserLicenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userLicenses, setUserLicenses] = useState<{ [key: string]: number }>(
    {}
  );
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [filteredAllUsers, setFilteredAllUsers] = useState<any[]>([]);
  const licenseService = new LicenseService();
  const userService = new UsersService();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const initialUserLicenses =
          licenseState.users[0] !== null
            ? licenseState?.users?.reduce((acc, userLicense) => {
                acc[userLicense.id] = userLicense.licentie_amount;
                return acc;
              }, {})
            : [];

        setUserLicenses(initialUserLicenses);

        const usersResponse = await userService.getUserNames(companyId, search);
        const licensedUserIds = new Set(
          licenseState?.users?.map((user) => user?.id_user)
        );

        const licensedUserIdsSet = new Set(licensedUserIds || []);

        const filtered = usersResponse.filter((user) => {
          return !licensedUserIdsSet.has(user.value);
        });

        setAllUsers(filtered);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [licenseState]);

  useEffect(() => {
    filterUsers("");
  }, [allUsers]);

  const filterUsers = (searchValue: string) => {
    let filtered = licenseState?.users[0] !== null ? licenseState.users : [];

    if (searchValue.trim() !== "") {
      filtered = filtered.filter((user) => {
        const firstName = user?.user?.geb_voornaam;
        const lastName = user?.user?.geb_naam;
        return (
          String(firstName).toLowerCase().includes(searchValue.toLowerCase()) ||
          String(lastName).toLowerCase().includes(searchValue.toLowerCase())
        );
      });
    }

    setFilteredUserLicenses(filtered);

    const filteredAll =
      searchValue.trim() === ""
        ? allUsers
        : allUsers.filter((user) => {
            return user.label.toLowerCase().includes(searchValue.toLowerCase());
          });

    setFilteredAllUsers(filteredAll);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    filterUsers(event.target.value);
  };

  const handleIncreaseItemCount = async (licenseId: number) => {
    const totalLicenses = licenseState.aantal;
    const currentTotalUserLicenses = Object.values(userLicenses).reduce(
      (acc, val) => acc + val,
      0
    );

    if (currentTotalUserLicenses < totalLicenses) {
      try {
        const newAmount = (userLicenses[licenseId] || 0) + 1;

        await licenseService.updateLicense(companyId, licenseId, {
          licentie_amount: newAmount,
        });

        setUserLicenses((prev) => ({
          ...prev,
          [licenseId]: newAmount,
        }));
      } catch (error) {
        console.error("Failed to update license:", error);
      }
    }
  };

  const handleDecreaseItemCount = async (licenseId: number, userId: string) => {
    if (userLicenses[licenseId] > 0) {
      try {
        const newAmount = userLicenses[licenseId] - 1;

        if (newAmount === 0) {
          await licenseService.removeUser(licenseId, companyId);
        } else {
          await licenseService.updateLicense(companyId, licenseId, {
            licentie_amount: newAmount,
          });
        }

        setUserLicenses((prev) => ({
          ...prev,
          [licenseId]: newAmount,
        }));
      } catch (error) {
        console.error("Failed to update license:", error);
      }
    }
  };

  const addUser = async (userId: string, name: string) => {
    try {
      const userLicenseId = await licenseService.addUser(
        licenseState.id,
        userId,
        companyId
      );

      setUserLicenses((prev) => ({
        ...prev,
        [userLicenseId]: 1,
      }));

      setFilteredUserLicenses((prev) => [
        ...prev,
        {
          geb_voornaam: name,
          id: userLicenseId,
          id_user: userId,
          licentie_amount: 1,
        },
      ]);
      setFilteredAllUsers((prev) =>
        prev.filter((user) => user.value !== userId)
      );
    } catch (error) {
      console.error("Failed to add user:", error);
    }
  };

  const tableHeaders: object = ["Naam", "Aantal"];

  const fixedColumns = [
    { key: "Productnummer", position: "left" },
    { key: "", position: "right" },
  ];

  return (
    <div>
      <div className="mt-1">
        <Progress
          percent={(
            (Object.values(userLicenses).reduce((acc, val) => acc + val, 0) /
              licenseState.aantal) *
            100
          ).toFixed(2)}
        />
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="overflow-y-hidden border dark:border-gray-700 rounded-lg mt-3">
          <SearchTable
            handleChange={handleChange}
            placeholder="Gebruiker zoeken..."
            value={search}
          />
          <Table header={tableHeaders} fixedColumns={fixedColumns}>
            {filteredUserLicenses.map((userLicense: any) => (
              <tr key={userLicense?.id}>
                <TableItem type="tiny">
                  {userLicense?.geb_voornaam ?? ""}{" "}
                  {userLicense?.geb_naam ?? ""}
                </TableItem>
                <TableItem type="tiny">
                  <div className="flex gap-1 items-center text-base">
                    {userLicenses[userLicense?.id] > 0 && (
                      <button
                        className="hover:text-red-500 px-2 py-1"
                        onClick={() =>
                          handleDecreaseItemCount(
                            userLicense?.id,
                            userLicense?.id_user
                          )
                        }
                      >
                        -
                      </button>
                    )}
                    {userLicenses[userLicense?.id] || 0}
                    {Object.values(userLicenses).reduce(
                      (acc, val) => acc + val,
                      0
                    ) < licenseState.aantal && (
                      <button
                        className="hover:text-green-500 px-2 py-1"
                        onClick={() => handleIncreaseItemCount(userLicense?.id)}
                      >
                        +
                      </button>
                    )}
                  </div>
                </TableItem>
              </tr>
            ))}
            {filteredAllUsers.map((user: any) => (
              <tr key={user.value}>
                <TableItem type="tiny">{user.label}</TableItem>
                <TableItem type="tiny">
                  <div className="flex gap-1 items-center text-base">
                    0
                    {Object.values(userLicenses).reduce(
                      (acc, val) => acc + val,
                      0
                    ) < licenseState.aantal && (
                      <button
                        className="hover:text-green-500 px-2 py-1"
                        onClick={() => addUser(user.value, user.label)}
                      >
                        +
                      </button>
                    )}
                  </div>
                </TableItem>
              </tr>
            ))}
          </Table>
        </div>
      )}
    </div>
  );
};

export default CurrentLicenses;
