import React, { ChangeEvent, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { text } from "stream/consumers";

interface InputFieldProps {
  type: "text" | "number" | "email" | "password" | "search";
  label?: string;
  info?: string;
  value?: string;
  name: string;
  style?: keyof typeof styles;
  placeholder: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  error?: string | undefined;
  defaultValue?: string | undefined;
}

const InputField: FC<InputFieldProps> = ({
  type,
  label,
  name,
  style = "default",
  info,
  placeholder,
  onChange,
  onBlur,
  error,
  value,
}) => {
  const { t } = useTranslation();
  const styles: object = {
    default:
      "border border-gray-300 dark:border-slate-600 text-sm rounded-lg block w-full px-2.5 py-1.5 bg-transparent border focus:outline-blue-700",
    transparant:
      "bg-transparent mb-2 border-b border-0 py-3 w-full rounded-none px-6 dark:border-gray-700",
  };

  const [iValue, setValue] = useState(value || "");

  return (
    <div className="relative">
      <span className="text-sm block font-normal">{info}</span>

      {label ? (
        <label
          htmlFor={name}
          className="block mb-2 text-sm font-medium text-slate-800 dark:text-slate-300"
        >
          {t(label)}
        </label>
      ) : (
        ""
      )}
      <input
        type={type}
        name={name}
        value={iValue}
        defaultValue={value}
        placeholder={t(placeholder)}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
        className={` ${styles[style]} ${
          error ? "border-red-500" : "border-gray-300"
        } `}
      />
    </div>
  );
};

export default InputField;
