import React, { FC, useEffect, useState } from "react";
import { AutoComplete } from "antd";
import { SimcardService } from "../../services/simcards/Simcards";
import { X } from "react-feather";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

const simcardService = new SimcardService();

type props = {
  onSelect: (value: string) => void;
  defaultValue: string;
  status: string;
  error: "" | "error" | "warning" | undefined;
  disabled?: boolean;
  includeESIM?: boolean;
};

const SelectSimcard: FC<props> = ({
  onSelect,
  defaultValue,
  status,
  error,
  disabled,
  includeESIM = false,
}) => {
  const companyId: number = useSelector(
    (state: RootState) => state.auth.roles[0]?.companyId
  );
  const [sims, setSims] = useState([]);

  useEffect(() => {
    if (companyId) {
      simcardService.getSimcards(companyId, "", status).then((res) => {
        setSims(res);
      });
    }
  }, [companyId]);

  const options = [
    ...(sims.map((sim) => ({
      label: sim.sim_unique_nr,
      value: sim.sim_unique_nr,
    })) || []),
    ...(includeESIM ? [{ label: "eSIM", value: "eSIM" }] : []),
  ];

  return (
    <AutoComplete
      defaultValue={defaultValue}
      value={defaultValue}
      style={{ width: "100%" }}
      onChange={onSelect}
      onSelect={onSelect}
      optionFilterProp="label"
      status={error}
      allowClear={{ clearIcon: <X size={14} /> }}
      options={options}
      filterOption={(inputValue, option) =>
        option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      }
      disabled={disabled}
    />
  );
};

export default SelectSimcard;
