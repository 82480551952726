import apiClient from "../../../api-client";

export class TicketService {
  public async getTickets(
    companyId: number,
    type?: string,
    typeValue?: number,
    status?: string
  ) {
    try {
      const queryParams: string[] = [];

      if (type && typeValue) {
        queryParams.push(`type=${type}`, `typeValue=${typeValue}`);
      }

      if (status) {
        queryParams.push(`status=${status}`);
      }

      const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";

      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/tickets${queryString}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching all tickets: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getTicket(id: number, companyId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/tickets/${id}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching ticket with id ${id}: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getUserTickets(userId: number, companyId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/tickets/user/${userId}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching tickets for user with id: ${userId}: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async postComment(
    ticketId: number,
    companyId: number,
    comment: Object
  ) {
    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/tickets/${ticketId}/comments`,
        {
          body: comment.body,
          author_id: comment.author_id,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error creating comment on ticket: ${ticketId}: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async createTicketZendesk(companyId: number, ticket: Object) {
    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/tickets`,
        {
          ...ticket,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error creating ticket: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async putCollaborators(
    ticketId: number,
    companyId: number,
    collaborators: { name: string; email: string }[]
  ) {
    try {
      const response = await apiClient.put(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/tickets/${ticketId}/addcollaborator`,
        { collaborators }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error adding collaborators to ticket: ${ticketId}: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async putEmployee(ticketId: number, companyId: number) {
    try {
      const response = await apiClient.put(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/tickets/${ticketId}/employee`
      );
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error adding employee to ticket: ${ticketId}: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }
}
