import React, { FC, ReactNode } from "react";

type Props = {
  children: ReactNode;
  type: string;
  gridSize?: string;
  size?: string;
};

const styles = {
  bordered:
    "w-full justify-center items-center rounded-md border dark:border-slate-800 py-3 px-5 lg:px-8",
  borderedTiny:
    "w-full justify-center items-center rounded-md border dark:border-slate-800",
  noStyle: "w-full justify-center items-center",
};

const gridSizes = {
  default: "grid grid-cols-4 gap-2",
  noGrid: "grid grid-cols-1 gap-2",
  "grid-1": "grid grid-cols-1",
  "grid-2": "grid grid-cols-2",
  "grid-3":
    "grid gap-y-8 gap-x-4 xl:gap-x-8 grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3",
  "grid-4": "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4",
  "grid-5": "grid grid-cols-5",
  "grid-6": "grid grid-cols-3 2xl:grid-cols-6 gap-3",
};

const sizes = {
  noSize: "min-h-0",
  smallest: "min-h-16",
  small: "min-h-32",
  medium: "min-h-64",
  large: "min-h-72",
};

const Box: FC<Props> = ({
  children,
  type,
  size = "small",
  gridSize = "default",
}) => {
  return (
    <div className={`${sizes[size]} ${styles[type]} ${gridSizes[gridSize]}`}>
      {children}
    </div>
  );
};

export default Box;
