import React, { FC, useState } from "react";
import Form from "../Form";
import SelectSimcard from "../../../organisms/simcards/SelectSimcard";
import Typography from "../../typography/Typography";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../state/store";
import { TicketService } from "../../../services/tickets/Tickets";
import { addToast } from "../../../state/toast/ToastSlice";
import { updateAside } from "../../../state/component/AsideSlice";
import { setSimcard } from "../../../state/simdata/SimcardsSlice";
import { SimcardService } from "../../../services/simcards/Simcards";

type props = {
  currentValue: string;
};

const SwapSimcard: FC<props> = ({ currentValue }) => {
  const [loading, setLoading] = useState(false);
  const [newSim, setNewSim] = useState("");
  const [error, setError] = useState("inactive");
  const sim = useSelector((state: RootState) => state.aside.data);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const ticketService = new TicketService();
  const simService = new SimcardService();
  const dispatch = useDispatch();

  const handleChange = async (event) => {
    setNewSim(event);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    if (!newSim || (newSim !== "eSIM" && newSim.length < 13)) {
      setError("error");
      setLoading(false);
      return false;
    }

    const prop = {
      sim_status: "Swap simkaart",
    };

    dispatch(updateAside({ props: prop, object: "sim" }));
    dispatch(setSimcard({ props: prop, key: sim.sim.key }));

    const update = await simService.updateSimcard(
      sim.sim.sim_id,
      {
        ...prop,
      },
      companyId
    );

    const description = `Swap Sim van ${sim.sim.sim_unique_nr} naar ${newSim}`;
    const subject = `Swap Sim ${sim.sim.sim_unique_nr}`;

    const ticketResponse = await ticketService.createTicketZendesk(companyId, {
      subject: subject,
      description: description,
      department: "Telecom",
      linkedResource: {
        id: sim.sim.sim_unique_nr,
        type: "TELECOM",
      },
    });

    dispatch(
      addToast({
        description: [
          "Swap sim",
          {
            sim_unique_nr: sim.sim.sim_unique_nr,
          },
        ],
        position: "bottomRight",
        style: "success",
      })
    );
    setLoading(false);
    setError("success");
    setNewSim("");
  };

  return (
    <div>
      <Form
        buttonLabel="Indienen"
        handleSubmit={handleSubmit}
        gridSize="grid-cols-2"
        loading={loading}
      >
        <Typography tag="label" type="label">
          Selecteer nieuwe simkaart
        </Typography>
        <SelectSimcard
          onSelect={handleChange}
          defaultValue={newSim}
          error={error}
          status={"inactive"}
          includeESIM={true} // Pass prop to enable eSIM in SelectSimcard
        />
      </Form>
    </div>
  );
};

export default SwapSimcard;
