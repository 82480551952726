import React from "react";
import Typography from "../../components/typography/Typography";
import RatePlanInfo from "../../components/typography/sim/RatePlanInfo";

const TarifRoaming = ({ roaming }) => {
  return roaming?.tarief_roaming ? (
    <div className="p-3">
      <div className="flex gap-3 mb-3">
        <Typography type="bold" tag="h4">
          {roaming.trf_roaming_price}:
        </Typography>
        <RatePlanInfo ratePlan={roaming} />
      </div>
      <Typography type="bold" tag="h4">
        {roaming.tarief_roaming.trf_typ_name}
      </Typography>
      <Typography type="label" tag="span">
        {roaming.tarief_roaming.trf_typ_countrys.info
          ? roaming.tarief_roaming.trf_typ_countrys.info
          : ""}
      </Typography>
      <div className="flex flex-wrap gap-1">
        {roaming.tarief_roaming.trf_typ_countrys.landen.map(
          (country, index) => (
            <Typography type="label" tag="span" addClass={"even:opacity-80"}>
              {country.name}
              {index < roaming.tarief_roaming.trf_typ_countrys.landen.length - 1
                ? ","
                : ""}
            </Typography>
          )
        )}
      </div>
    </div>
  ) : (
    ""
  );
};

export default TarifRoaming;
