import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, DatePicker } from "antd";
import { RootState } from "../../../state/store";
import { ProjectsService } from "../../../services/project/Projects";
import TextHeader from "../../headers/TextHeader";
import LoadingSpinner from "../../buttons/LoadingSpinner";
import Table from "../../tables/Table";
import TableRow from "../../tables/TableRow";
import TableItem from "../../tables/TableItem";
import { UsersService } from "../../../services/users/Users";
import { setAside } from "../../../state/component/AsideSlice";
import { setCurrentProject } from "../../../state/project/ProjectSlice";
import { Edit } from "react-feather";
import Typography from "../../typography/Typography";

interface Sim {
  id: string;
  sim_unique_nr: string;
}

const SimOffboarding: FC = () => {
  const [sims, setSims] = useState<Sim[]>([]);
  const [loading, setLoading] = useState(true);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const task = useSelector((state: RootState) => state.aside.data.task);

  const userService = new UsersService();
  const projectService = new ProjectsService();

  const dispatch = useDispatch();

  useEffect(() => {
    fetchSims();
  }, []);

  const fetchSims = async () => {
    setLoading(true);
    try {
      const userSims = await userService.getUserSims(
        companyId,
        task.metadata[0].value
      );
      setSims(userSims.sim);
    } catch (error) {
      console.error("Error fetching sims:", error);
    } finally {
      setLoading(false);
    }
  };

  const viewDetail = (sim: object, i: number) => {
    dispatch(
      setAside({
        active: true,
        component: "simDetail",
        data: { sim: { key: i, ...sim } },
        crumb: "Simkaart",
      })
    );
  };

  const handleSubmit = async () => {
    dispatch(
      setAside({
        active: 0,
      })
    );

    try {
      const project = await projectService.finishAssignment(
        task.phase_id,
        task.rule_id,
        companyId
      );

      dispatch(setCurrentProject(project.result));
    } catch (error) {
      console.error("Error adding assignments:", error);
    }
  };

  const tableHeaders = ["Simkaart", "Acties"];

  return (
    <div className="overflow-hidden">
      <TextHeader title={"Simkaarten"} />
      <Typography tag={"p"} type={"default"}>
        U kan de simkaarten beheren door op de detail knop te drukken.
      </Typography>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="col-span-2 border dark:border-slate-800 rounded-lg h-full">
            <Table header={tableHeaders} onClick={() => viewDetail(sim, i)}>
              {sims.map((sim, i) => (
                <TableRow key={sim.id}>
                  <TableItem type="tiny">{sim.sim_unique_nr}</TableItem>
                  <TableItem type="tiny">
                    <button onClick={() => viewDetail(sim, i)}>
                      <Edit size={18} />
                    </button>
                  </TableItem>
                </TableRow>
              ))}
            </Table>
          </div>
          <div className="mt-3">
            <Button disabled={sims.length > 0} onClick={handleSubmit}>
              Taak afronden
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SimOffboarding;
