import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SimcardService } from "../../services/simcards/Simcards";

const SimService = new SimcardService();

interface SimBrutoState {
  loading: boolean;
  simBruto: object;
  error: string;
}

const initialState: SimBrutoState = {
  loading: false,
  simBruto: [],
  error: "",
} as SimBrutoState;

export const fetchSimMetricGroups = createAsyncThunk(
  "simBruto/getBruto",
  async ({ props, companyId }: { props: string[]; companyId: string }) => {
    const output = { ...props };
    return await SimService.getBruto(output, Number(companyId));
  }
);

const simBrutoSlice = createSlice({
  name: "simBruto",
  initialState: initialState,
  reducers: {
    setMetricGroups: (state, action) => {
      state.simBruto = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSimMetricGroups.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchSimMetricGroups.fulfilled,
      (state, action: PayloadAction<object>) => {
        state.simBruto = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(fetchSimMetricGroups.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { setMetricGroups } = simBrutoSlice.actions;

export default simBrutoSlice.reducer;
