import React, { FC, useEffect, useState } from "react";

import TableItem from "../../../components/tables/TableItem";
import Table from "../../../components/tables/Table";
import Box from "../../../components/boxes/Box";
import CurrencyFormatter from "../../../utils/text/CurrencyFormatter";
import { SumObjects } from "../../../utils/numbers/SumObject";
import { SimcardService } from "../../../services/simcards/Simcards";
import LoadingSpinner from "../../../components/buttons/LoadingSpinner";
import Typography from "../../../components/typography/Typography";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { useTranslation } from "react-i18next";

type SimUsage = {
  props: string[];
};

const SimUsagePerUser: FC<SimUsage> = ({ props }) => {
  const { t } = useTranslation();
  const service = new SimcardService();
  const [usage, setUsage] = useState([]);
  const [params, setParams] = useState(props);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const tableHeaders: object = ["Jaar", "Maand", "Prijs"];

  useEffect(() => {
    if (companyId) {
      try {
        service.getUsagePerUser(params, companyId).then((response) => {
          setUsage("results" in response ? response.results : response);
        });
      } catch (err) {
        console.error("Error fetching SIM data:", err);
      }
    }
  }, [props.phoneNumbers[0], companyId]);

  return (
    <>
      {usage.length > 0 ? (
        <Box gridSize="grid-1" type="noStyle">
          <div className="border overflow-y-hidden dark:border-gray-700 rounded-lg">
            <Table header={tableHeaders}>
              {usage.map((item, key) => (
                <tr key={key}>
                  <TableItem type="tiny">{item.year}</TableItem>
                  <TableItem type="tiny">{t(`m${item.month}`)}</TableItem>
                  <TableItem type="tiny">
                    <CurrencyFormatter price={item["sum(`value`)"]} />
                  </TableItem>
                </tr>
              ))}
              <tr>
                <TableItem colspan={2}>
                  <Typography type="bold" tag="span">
                    Totaal
                  </Typography>
                </TableItem>
                <TableItem>
                  <Typography type="bold" tag="span">
                    <CurrencyFormatter
                      price={SumObjects(usage, "sum(`value`)")}
                    />
                  </Typography>
                </TableItem>
              </tr>
            </Table>
          </div>
        </Box>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};
export default SimUsagePerUser;
