import React, { FC, useState } from "react";
import SearchTable from "../inputs/search/SearchTable";
import Table from "../tables/Table";
import TableItem from "../tables/TableItem";
import { TruncateString } from "../../utils/text/TruncateString";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import {
  addLicense,
  removeLicense,
  updateLicenseQuantity,
} from "../../state/license/LicenseSlice";
import { useTranslation } from "react-i18next";

const LicenseOverview: FC = ({ licenses }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [filteredLicenses, setFilteredLicenses] = useState(licenses);
  const selectedLicenses = useSelector(
    (state: RootState) => state.license.licenses
  );
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    filterLicenses(event.target.value);
  };

  const filterLicenses = (searchValue: string) => {
    const filtered = licenses.filter((license: any) => {
      return (
        (license.licentie
          ? license.licentie.lic_licentie
          : license.lic_licentie
        )
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        (license.licentie ? license.licentie.lic_type : license.lic_type)
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      );
    });
    setFilteredLicenses(filtered);
  };

  const handleIncreaseItemCount = (id: string, name: string) => {
    dispatch(
      addLicense({
        [id]: { quantity: 1, name },
      })
    );
  };

  const handleDecreaseItemCount = (id: string) => {
    const license = licenses.find((license) => license.id === id);
    if (license && license.quantity === 1) {
      dispatch(removeLicense(id));
    } else {
      dispatch(
        updateLicenseQuantity({
          id,
          quantity: Math.max((license?.quantity || 0) - 1, 0),
        })
      );
    }
  };

  const tableHeaders: string[] = ["Licentie", "Categorie", "Aantal"];

  return (
    <div className="overflow-hidden">
      <div>
        <SearchTable
          handleChange={handleChange}
          placeholder="Licentie zoeken..."
          value={search}
        />
        <Table header={tableHeaders}>
          {filteredLicenses && filteredLicenses?.length > 0 ? (
            filteredLicenses.map((lic) => {
              const license = lic.licentie ? lic.licentie : lic;
              return (
                <tr key={license.id}>
                  <TableItem type="tiny-np">
                    <TruncateString text={license.lic_licentie} limit={35} />
                  </TableItem>
                  <TableItem type="tiny-np">{license.lic_type}</TableItem>
                  <TableItem type="tiny-np">
                    <div className="flex gap-1 items-center text-base">
                      <button
                        className="hover:text-red-500 px-2 py-1"
                        onClick={() => handleDecreaseItemCount(license.id)}
                      >
                        -
                      </button>
                      {selectedLicenses[license.id]?.quantity || 0}
                      <button
                        className="hover:text-green-500 px-2 py-1"
                        onClick={() =>
                          handleIncreaseItemCount(
                            license.id,
                            license.lic_licentie
                          )
                        }
                      >
                        +
                      </button>
                    </div>
                  </TableItem>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={tableHeaders.length} className="text-center py-4">
                {t("Geen licenties gevonden.")}
              </td>
            </tr>
          )}
        </Table>
      </div>
    </div>
  );
};

export default LicenseOverview;
