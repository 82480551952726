import React, { FC } from "react";
import Typography from "../../../components/typography/Typography";
import Table from "../../../components/tables/Table";
import TableItem from "../../../components/tables/TableItem";
import TableRow from "../../../components/tables/TableRow";

const UserSims: FC = ({ sims }) => {
  const tableHeaders: object = ["Simkaart#", "GSM-nummer", "Tariefplan"];

  return (
    <div className="h-full">
      {sims?.length > 0 ? (
        <div className="border-b dark:border-gray-700">
          <Table header={tableHeaders}>
            {sims?.map((sim: any, i: string) => (
              <TableRow key={i}>
                <TableItem>{sim.sim_unique_nr ?? "eSim"}</TableItem>
                <TableItem>{sim.sim_gsm_nr ?? "/"}</TableItem>
                <TableItem>{sim.tariefplan?.trf_naam}</TableItem>
              </TableRow>
            ))}
          </Table>
        </div>
      ) : (
        <div className="h-full">
          <Typography type={"default"} tag={"h1"}>
            Geen simkaarten
          </Typography>
        </div>
      )}
    </div>
  );
};

export default UserSims;
