import React, { FC } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  name: string;
  disabled?: boolean;
};

const ToggleSwitch: FC<Props> = ({
  label,
  checked = false,
  onChange,
  name,
  disabled = false,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <label
        className={`relative inline-flex items-center cursor-pointer ${
          disabled ? "opacity-50 pointer-events-none" : ""
        }`}
      >
        <input
          type="checkbox"
          className="sr-only peer"
          name={name}
          checked={!disabled && checked}
          onChange={(e) => onChange(e)}
          disabled={disabled}
        />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-700"></div>
        <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
          {t(label)}
        </span>
      </label>
    </div>
  );
};

export default ToggleSwitch;
