import apiClient from "../../../api-client";

interface Project {
  [x: string]: any;
}

export class ProjectsService {
  public async getProjects(companyId: number): Promise<Project[]> {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/projects`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching all projects: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getProject(
    projectId: number,
    companyId: number
  ): Promise<Project[]> {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/projects/${projectId}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching project with id: ${projectId}: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getUserProjects(
    id: number,
    companyId: number
  ): Promise<Project[]> {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/projects/user/${id}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching projects for user: ${id}: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async createProject(
    newProjectData: Partial<Project>,
    companyId: number
  ) {
    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/projects`,
        newProjectData
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error creating project: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async addAssignments(
    assignments: object[],
    projFaseId: number,
    projFaseRegel_id: number,
    companyId: number
  ) {
    try {
      const response = await apiClient.patch(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/projects/phase/${projFaseId}/assignments`,
        { assignments, phaseRuleId: projFaseRegel_id }
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error adding assignments: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async finishAssignment(
    projFaseId: number,
    projFaseRegel_id: number,
    companyId: number
  ) {
    try {
      const response = await apiClient.patch(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/projects/phase/${projFaseId}/assignments/${projFaseRegel_id}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error adding assignments: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }
}
