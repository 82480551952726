import React, { FC, useState } from "react";
import { Form } from "antd";
import FormField from "../../components/inputs/text/FormField";
import SelectBox from "../../components/inputs/selectbox/SelectBox";
import FormButton from "../../components/buttons/FormButton";
import { addToast } from "../../state/toast/ToastSlice";
import { TicketService } from "../../services/tickets/Tickets";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { setAside } from "../../state/component/AsideSlice";

type TicketData = {
  type: string;
  type_value: number;
};

const TicketForm: FC<TicketData> = ({ type, type_value }) => {
  const [form] = Form.useForm();
  const [ticketData, setTicketData] = useState({ department: "IT" });
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const ticketService = new TicketService();
  const dispatch = useDispatch();

  const handleSelect = (value: string, label: string) => {
    setTicketData((prev) => ({
      ...prev,
      department: value,
    }));
  };

  const onCreate = async (values: any) => {
    const updatedValues = {
      ...ticketData,
      ...values,
      // tic_ser_id: assetId,
      linkedResource: type_value ? { id: type_value, type: type } : undefined,
    };

    await ticketService.createTicketZendesk(companyId, updatedValues);

    dispatch(
      setAside({
        active: 0,
      })
    );

    dispatch(
      addToast({
        description: "Het ticket is aangemaakt.",
        position: "bottomRight",
        style: "success",
      })
    );
  };

  return (
    <Form labelAlign="left" layout="vertical" form={form} onFinish={onCreate}>
      <div className="mt-3 grid grid-cols-3 gap-x-5">
        <div className="col-span-2">
          <FormField
            type="text"
            label="Onderwerp"
            placeholder=""
            name="subject"
            form={form}
            isRequired={true}
          />
        </div>
        <div className="mt-auto pb-[24px]">
          <SelectBox
            options={[
              { value: "IT", label: "IT", searchLabel: "IT" },
              { value: "Telecom", label: "Telecom", searchLabel: "Telecom" },
            ]}
            defaultValue={"IT"}
            onChange={handleSelect}
          />
        </div>
        <div className="col-span-3">
          <FormField
            type="textarea"
            label="Omschrijving"
            placeholder=""
            name="description"
            form={form}
            isRequired={true}
            className=""
          />
        </div>
        <div className="col-span-3 my-2">
          <FormButton form={true} text={"Ticket aanmaken"} />
        </div>
      </div>
    </Form>
  );
};

export default TicketForm;
