import React, { useEffect, useRef, useState } from "react";
import Box from "../../components/boxes/Box";
import Tag from "../../components/tags/Tag";
import Typography from "../../components/typography/Typography";
import EmployeeThumb from "../../components/EmployeeThumb";
import Time from "../../components/typography/Time";
import DateFormatted from "../../components/typography/Date";
import ChatBubble from "../../components/boxes/ChatBubble";
import { TicketService } from "../../services/tickets/Tickets";
import { User, UserPlus, X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import CommentForm from "../../components/forms/ticket/CommentForm";
import { setComponent } from "../../state/component/AsideSlice";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../components/buttons/LoadingSpinner";
import Cta from "../../components/buttons/Cta";
import SelectBox from "../../components/inputs/selectbox/SelectBox";
import { Button, Space, Tooltip } from "antd";
import { UsersService } from "../../services/users/Users";
import { addToast } from "../../state/toast/ToastSlice";
import { updateTicket } from "../../state/tickets/ticketsSlice";

const TicketDetailContainer = () => {
  const { t } = useTranslation();
  const ticketState = useSelector((state: RootState) => state.aside);
  const service = new TicketService();
  const userService = new UsersService();
  const [ticket, setTicket] = useState({});
  const [loading, setLoading] = useState(true);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [employeeLoading, setEmployeeLoading] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [collaboratorLoading, setCollaboratorLoading] = useState(false);
  const [comments, setComments] = useState<Object[]>([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const debounceTimeout = useRef<number | undefined>(undefined);
  const companyId: number = useSelector(
    (state: RootState) => state.auth.roles[0]?.companyId
  );
  const email: string = useSelector((state: RootState) => state.profile.email);
  const user = useSelector((state: RootState) => state.profile);
  const selectBoxRef = useRef<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTicketData = async () => {
      try {
        const ticket = await service.getTicket(
          ticketState.data.ticket.id,
          companyId
        );
        setTicket(ticket.zendesk);
        setComments(ticket.zendesk.comments);
      } catch (error) {
        console.error("Error fetching ticket:", error);
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    setAddUser(false);
    fetchTicketData();
  }, [ticketState]);

  const fetchOptions = async (term: string) => {
    setOptionsLoading(true);

    const trimmedTerm = term.trim();

    if (trimmedTerm.length >= 3 && companyId) {
      const fetchedOptions = await userService.getUsers(companyId, trimmedTerm);
      const processedOptions = fetchedOptions.map((option) => ({
        label: option.geb_voornaam + " " + option.geb_naam,
        value: option.email,
        searchLabel: option.geb_voornaam + " " + option.geb_naam,
      }));
      setOptions(processedOptions);
    } else {
      setOptions([]);
    }
    setOptionsLoading(false);
  };

  const handleSearch = (term: string) => {
    clearTimeout(debounceTimeout.current);

    debounceTimeout.current = window.setTimeout(() => {
      fetchOptions(term);
    }, 500);
  };

  const handleCommentSubmit = async (body: string) => {
    try {
      const comment = {
        id: Math.random().toString(36).substr(2, 9),
        body: body,
        author: {
          id: ticket.requester_id,
          name: user.firstName + " " + user.lastName,
          email: user.email,
        },
        created_at: new Date().toISOString(),
      };

      setComments([...comments, comment]);

      await service.postComment(ticketState.data.ticket.id, companyId, {
        body: body,
        author_id: ticket.requester_id,
      });
    } catch (error) {
      console.error("Error submitting comment:", error);
      dispatch(
        addToast({
          description: "Failed to add comment. Please try again.",
          position: "bottomRight",
          style: "error",
        })
      );
    }
  };

  const putEmployee = async () => {
    setEmployeeLoading(true);
    const updatedTicket = await service.putEmployee(
      ticketState.data.ticket.id,
      companyId
    );

    setTicket(updatedTicket.empId.zendesk);
    dispatch(updateTicket(updatedTicket.empId.zendesk));
    setEmployeeLoading(false);
  };

  const putCollaborators = async () => {
    setCollaboratorLoading(true);
    const formattedCollaborators = selectedOptions.map((collaborator) => ({
      name: collaborator.label,
      email: collaborator.value,
    }));

    try {
      await service.putCollaborators(
        ticketState.data.ticket.id,
        companyId,
        formattedCollaborators
      );
      setOptions([]);
      setSelectedOptions([]);
      setAddUser(false);
      setDropdownVisible(false);

      dispatch(
        addToast({
          description: "Het ticket is met de gebruiker(s) gedeeld",
          position: "bottomRight",
          style: "success",
        })
      );
    } catch (error) {
      console.error("Error updating collaborators:", error);
    } finally {
      setCollaboratorLoading(false);
    }
  };

  const toHardware = () => {
    dispatch(setComponent("assetDetail"));
  };

  const handleSelectChange = async (values: string[]) => {
    setSelectedOptions(
      options.filter((option) => values.includes(option.value))
    );
  };

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : ticket ? (
        <>
          <Box gridSize="grid-4" type="borderedTiny" size="noSize">
            <div className="col-span-4 px-6 py-4 grid grid-cols-4 items-center">
              <Typography tag="p" type="title">
                Ticket: #{ticketState.data.ticket.id}
              </Typography>
              <div className="col-span-3 w-full flex gap-1 items-center justify-end">
                <SelectBox
                  onSearch={handleSearch}
                  options={options}
                  loading={optionsLoading}
                  search={true}
                  notFoundText="Geen gebruikers"
                  props={{
                    className: `w-full transition-all origin-right duration-200 ${
                      addUser ? "scale-100" : "scale-0"
                    }`,
                    mode: "multiple",
                    placeholder: "Deel ticket met",
                    value: selectedOptions,
                    open: dropdownVisible,
                    ref: selectBoxRef,
                    onDropdownVisibleChange: (visible) =>
                      setDropdownVisible(visible),
                    onChange: handleSelectChange,
                    dropdownRender: (menu) => (
                      <>
                        {menu}
                        <Space
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            justifyItems: "center",
                            paddingTop: 5,
                          }}
                        >
                          <Cta
                            style="noMargin"
                            text="Deel ticket"
                            loading={collaboratorLoading}
                            onClick={putCollaborators}
                          />
                        </Space>
                      </>
                    ),
                  }}
                />
                <Tooltip title={addUser ? "Delen sluiten" : "Deel ticket met"}>
                  <Button
                    onClick={() => {
                      setAddUser((prev) => !prev);
                      if (!addUser) {
                        setTimeout(() => {
                          selectBoxRef.current?.focus();
                        }, 0);
                      }
                    }}
                  >
                    {addUser ? <X /> : <UserPlus />}
                  </Button>
                </Tooltip>
              </div>
            </div>
            <div className="col-span-4 px-6 mb-4">
              <Typography tag="p" type="semibold">
                {ticket.subject}&nbsp;
              </Typography>
              <Typography tag="p" type="default">
                {ticket?.comments[0]?.plain_body}
              </Typography>
            </div>
          </Box>

          <div className="mb-2"></div>

          <Box gridSize="grid-4" type="borderedTiny" size="noSize">
            <div className="col-span-4 px-6 py-2">
              <ul className="flex flex-wrap">
                <li className="mr-4">
                  <Tag
                    label={ticket.metadata?.custom?.username}
                    style="neutral"
                    icon={<User size={14} />}
                    lowercase={1}
                  />
                </li>
                <li className="mr-4">
                  <Time icon={1} time={ticket.created_at} />
                </li>
                <li>
                  <DateFormatted icon={1} date={ticket.created_at} />
                </li>
              </ul>
            </div>
          </Box>
          <div className="mb-2"></div>

          {/* <Box gridSize="grid-4" type="borderedTiny" size="noSize"> */}
          <div className="md:flex md:justify-between w-full items-center rounded-md border dark:border-slate-800 px-6">
            <div className="my-4 md:my-2 flex items-center md:justify-center gap-2">
              <Typography tag="span" type="label">
                Behandelaar
              </Typography>
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                  {ticket.custom_fields?.find(
                    (field) => field.id === 21560114189586
                  ).value ? (
                    <EmployeeThumb
                      image={ticket?.medewerker?.med_foto}
                      name={ticket?.medewerker?.med_naam}
                      size={8}
                    />
                  ) : companyId === 1 ? (
                    <Cta
                      style="noMargin"
                      text={"Aannemen"}
                      onClick={putEmployee}
                      loading={employeeLoading}
                    />
                  ) : (
                    t("Niet toegewezen")
                  )}
                </div>
                <div className="flex-1 min-w-0 ml-2">
                  <Typography tag="p" type="label">
                    {ticket.metadata?.medewerker?.med_naam}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="my-4 md:my-2 flex gap-2 items-center md:justify-center">
              <Typography tag="span" type="label">
                Afdeling
              </Typography>
              {ticket.metadata?.custom?.department ? (
                <Tag
                  style="muted"
                  label={ticket.metadata?.custom?.department}
                />
              ) : (
                " /"
              )}
            </div>
            <div className="my-4 md:my-2 flex gap-2 items-center md:justify-center">
              <Typography tag="span" type="label">
                Status
              </Typography>
              <Tag style={ticket?.status} label={ticket?.status} />
            </div>
          </div>

          <div className="mt-2">
            <Box gridSize="grid-1" type="borderedTiny">
              <div className="py-10 max-h-[70vh] lg:max-h-[50vh] overflow-auto">
                {comments?.slice(1).map((item, index) => {
                  const currentDate = new Date(
                    item.created_at
                  ).toLocaleDateString();

                  const previousDate =
                    index > 0
                      ? new Date(
                          comments[index - 1].created_at
                        ).toLocaleDateString()
                      : null;

                  return (
                    <div key={item.id}>
                      {currentDate !== previousDate && (
                        <div className="flex justify-center text-center">
                          <Tag style={"muted"} label={currentDate} />
                        </div>
                      )}
                      <ChatBubble
                        message={item.body}
                        author={item.medewerker}
                        name={item.author.name}
                        time={item.created_at}
                        isAuthor={item.author.email === email}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="pt-5 px-4 mb-4">
                <CommentForm onSubmit={handleCommentSubmit} />
              </div>
            </Box>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default TicketDetailContainer;
