import apiClient from "../../../api-client";
import { AppendSearchParams } from "../../utils/AppendSearchParams";

interface Simcard {
  sim_id: number;
  sim_unique_nr: string;
  sim_type: string;
  sim_status: string;
  [x: string]: any;
}

interface getSimcard {
  simId: number;
  search?: string;
}

export class SimcardService {
  public async getSimcard(
    simId: number,
    companyId: number
  ): Promise<Simcard[]> {
    try {
      const url = `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/sims/${simId}`;

      const response = await apiClient.get(url);
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching simcard: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async updateSimcard(simId: number, value: object, companyId: number) {
    try {
      const response = await apiClient.patch(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/sims/${simId}`,
        value
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error updating simcard: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async updateOrCreateProximusSim(simData: object, companyId: number) {
    try {
      const response = await apiClient.patch(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/sims/updateorcreate`,
        simData
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error updating or creating simcard: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getSimcards(
    companyId: number,
    search?: string,
    status?: string
  ): Promise<Simcard[]> {
    try {
      const queryParams = new URLSearchParams();
      if (search) queryParams.append("search", search);
      if (status) queryParams.append("status", status);

      const url = `${
        process.env.GATSBY_SSO_BACKEND_API_URL
      }/company/${companyId}/sims${
        queryParams.toString() ? `?${queryParams.toString()}` : ""
      }`;

      const response = await apiClient.get(url);
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching all simcards: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getSimOptions(
    companyId: number,
    search?: string
  ): Promise<Simcard[]> {
    try {
      const queryParams = new URLSearchParams();
      if (search) queryParams.append("search", search);

      const url = `${
        process.env.GATSBY_SSO_BACKEND_API_URL
      }/company/${companyId}/sims/options${
        queryParams.toString() ? `?${queryParams.toString()}` : ""
      }`;

      const response = await apiClient.get(url);
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching simcard options: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  // NOT USED ANYWHERE
  public async getTariefplannen(): Promise<Simcard[]> {
    // const response = await fetch(
    //   `${process.env.GATSBY_PORTAL_API_URL}/api/mobileplans`
    // );
    // return await response.json();
  }

  // Change call to new backend
  public async getBruto(props: string[], companyId: number) {
    const raw = {
      unit: "bruto",
      ...props,
      limit: 36,
      offset: 0,
    };

    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/metrics`,
        { ...raw }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error getting bruto: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async getUsage(
    companyId: number,
    year: number,
    months: object,
    props?: string[]
  ) {
    const raw = {
      unit: "usage",
      years: [year],
      months: months,
      ...props,
      limit: 36,
      offset: 0,
    };

    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/metrics`,
        { ...raw }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error getting bruto: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async getUsagePerUser(props: string[], companyId: number) {
    const raw = {
      unit: "bruto",
      // groupBy: ["phone_number"],
      ...props,
      limit: 12,
      offset: 0,
    };

    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/metrics`,
        { ...raw }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error getting bruto: ${error.response?.statusText || error.message}`
      );
    }
  }

  // NOT USED ANYWHERE
  public async removeSimcard(simId: number) {
    // const myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // const raw = JSON.stringify({
    //   simId: simId,
    // });
    // const requestOptions: object = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };
    // const response = await fetch(
    //   `${process.env.GATSBY_PORTAL_API_URL}/api/simcard/danger/remove`,
    //   requestOptions
    // );
    // return await response.json();
  }
}
