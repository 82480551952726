export const getTimeAgo = (dateString: string, t: any): string => {
  const now = new Date();
  const notificationDate = new Date(dateString);

  if (isNaN(notificationDate.getTime())) {
    return "Invalid date";
  }

  const diffInMilliseconds = now.getTime() - notificationDate.getTime();
  const diffInMinutes = Math.floor(diffInMilliseconds / 1000 / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInDays >= 1) {
    return `${diffInDays} ${diffInDays > 1 ? t("days ago") : t("day ago")}`;
  } else if (diffInHours >= 1) {
    return `${diffInHours} ${diffInHours > 1 ? t("hours ago") : t("hour ago")}`;
  } else if (diffInMinutes >= 1) {
    return `${diffInMinutes} ${
      diffInMinutes > 1 ? t("minutes ago") : t("minute ago")
    }`;
  } else {
    return t("Just now");
  }
};
