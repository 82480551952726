import React, { ReactNode, FC, useState, useEffect } from "react";
import Sidebar from "../navigation/Sidebar";
import Navbar from "../navigation/Navbar";
import CheckLogin from "../../utils/Auth";
import { Provider } from "react-redux";
import { persistor, store } from "../../state/store";
import ToastNotifications from "../toasts/ToastNotifications";
import { PersistGate } from "redux-persist/integration/react";
import Aside from "./Aside";
import ThemeProvider from "../theme/Theme";
import AuthCheckContainer from "../auth/AuthCheckContainer";
import SearchBar from "../search/SearchBar";
import NotificationModal from "../notifications/NotificationModal";

type Layout = {
  children: ReactNode;
};

const Layout: FC<Layout> = ({ children }) => {
  // CheckLogin();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthCheckContainer />
        <ThemeProvider>
          <main className="flex flex-col h-screen dark:bg-gray-900 dark:text-slate-300 overflow-y-hidden">
            <div className="flex flex-1 overflow-y-auto">
              <div className="flex">
                <Sidebar />
              </div>
              <div className="flex flex-1 flex-col overflow-y-auto">
                <div>
                  <Navbar />
                </div>
                <div className="max-w-full px-5 md:px-10 xl:px-20 py-10 md:mt-0">
                  <SearchBar />
                  {children}
                </div>
              </div>
            </div>
          </main>
          <Aside />
          <NotificationModal />
          <ToastNotifications />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default Layout;
