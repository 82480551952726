import { combineReducers, configureStore, Middleware } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";
import simDataReducer from "../state/simdata/SimDataSlice";
import simBrutoReducer from "../state/simdata/SimBrutoSlice";
import simcardReducer from "../state/simdata/SimcardsSlice";
import cartReducer, { clearCart } from "../state/shop/shopSlice";
import filterReducer, { resetFilters } from "../state/shop/filterSlice";
import cyodReducer, { clearCyodSelection } from "../state/shop/cyodSlice";
import UserReducer from "./user/UserSlice";
import groupReducer from "./group/GroupSlice";
import branchReducer from "./branch/BranchSlice";
import hardwareReducer from "./hardware/HardwareSlice";
import licenseReducer from "./license/LicenseSlice";
import licenseRequestReducer from "./license/LicenseRequestSlice";
import projectReducer from "./project/ProjectSlice";
import couponReducer from "./coupon/CouponSlice";
import AsideReducer from "./component/AsideSlice";
import toastReducer from "../state/toast/ToastSlice";
import themeReducer from "../state/theme/themeSlice";
import groupProductsReducer from "./group/GroupProductsSlice";
import profileReducer from "./profile/ProfileSlice";
import authReducer from "./auth/AuthSlice";
import searchReducer from "./search/searchBarSlice";
import notificationReducer from "./notification/NotificationSlice";
import companiesReducer from "./companies/CompaniesSlice";
import ticketsReducer from "./tickets/ticketsSlice";

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};

const storage =
  typeof window !== "undefined"
    ? createWebStorage("local")
    : createNoopStorage();

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["cyod", "cart", "filter", "theme", "companies"],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    simData: simDataReducer,
    simBruto: simBrutoReducer,
    simcards: simcardReducer,
    cart: cartReducer,
    filter: filterReducer,
    user: UserReducer,
    group: groupReducer,
    branch: branchReducer,
    hardware: hardwareReducer,
    license: licenseReducer,
    licenseRequest: licenseRequestReducer,
    project: projectReducer,
    aside: AsideReducer,
    toast: toastReducer,
    cyod: cyodReducer,
    coupon: couponReducer,
    theme: themeReducer,
    groupProducts: groupProductsReducer,
    profile: profileReducer,
    auth: authReducer,
    searchBar: searchReducer,
    notification: notificationReducer,
    companies: companiesReducer,
    tickets: ticketsReducer,
  })
);

const clearLocalStorageMiddleware: Middleware =
  () => (next) => (action: any) => {
    if (action.type === "CLEAR_LOCAL_STORAGE") {
      store.dispatch(clearCyodSelection());
      store.dispatch(clearCart());
      store.dispatch(resetFilters());
    }
    return next(action);
  };

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
        ignoredPaths: ["register"],
      },
    }).concat(clearLocalStorageMiddleware),
});

setInterval(() => {
  store.dispatch({ type: "CLEAR_LOCAL_STORAGE" });
}, 2 * 24 * 60 * 60 * 1000); // 2 days in milliseconds

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
