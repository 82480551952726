import { Tooltip } from "antd";
import React, { FC } from "react";
import { User } from "react-feather";

type Props = {
  image: string;
  name: string;
  addClass?: string;
  size?: number;
};

const EmployeeThumb: FC<Props> = ({ image, name, addClass = "", size = 8 }) => {
  const sizeClass = `h-${size} w-${size}`;

  return (
    <span>
      {image ? (
        <Tooltip title={name}>
          <img
            src={image}
            alt={name}
            className={`rounded-full ring-2 ring-blue-700 ${sizeClass} hover:scale-125 transition-all ${addClass}`}
          />
        </Tooltip>
      ) : (
        <Tooltip title={"CMC"}>
          <div
            className={`relative rounded-full ring-2 p-0.5 ring-blue-700 ${sizeClass} hover:scale-125 transition-all flex justify-center items-center ${addClass}`}
          >
            <p className="text-blue-700 font-semibold text-xs">
              {/* CMC */}
              <User />
            </p>
          </div>
        </Tooltip>
      )}
    </span>
  );
};

export default EmployeeThumb;
