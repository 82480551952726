import { PayloadAction, createSlice } from "@reduxjs/toolkit";
interface Role {
  role: string;
  companyId: number;
}

interface Module {
  mod_name: string;
}

interface AuthState {
  roles: Role[];
  modules: Module[];
}

const initialState: AuthState = {
  roles: [],
  modules: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setRoles: (state, action: PayloadAction<Role[]>) => {
      state.roles = action.payload;
    },
    addRole: (state, action: PayloadAction<Role>) => {
      state.roles.push(action.payload);
    },
    removeRole: (
      state,
      action: PayloadAction<{ role: string; companyId: number }>
    ) => {
      state.roles = state.roles.filter(
        (r) =>
          r.role !== action.payload.role ||
          r.companyId !== action.payload.companyId
      );
    },
    clearRoles: (state) => {
      state.roles = [];
    },
    setModules: (state, action: PayloadAction<Module[]>) => {
      state.modules = action.payload;
    },
    clearModules: (state) => {
      state.modules = [];
    },
  },
});

export const {
  setRoles,
  addRole,
  removeRole,
  clearRoles,
  setModules,
  clearModules,
} = authSlice.actions;

export default authSlice.reducer;
