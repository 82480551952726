import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { setAside } from "../../state/component/AsideSlice";
import { Tooltip } from "antd";
import EmployeeThumb from "../../components/EmployeeThumb";
import { Plus } from "react-feather";

type Props = {
  users: [];
};

const ProjectUsers: FC<Props> = ({ users }) => {
  const dispatch = useDispatch();
  const project = useSelector(
    (state: RootState) => state.project.currentProject
  );

  const maxDisplayedUsers = 3;
  const displayedUsers = users.slice(0, maxDisplayedUsers);
  const remainingUserCount = users.length - maxDisplayedUsers;

  const viewDetail = (users: []) => {
    dispatch(
      setAside({
        active: true,
        component: "projectUsers",
        data: { users: users },
        crumb: "Project gebruikers",
      })
    );
  };

  return (
    <div className="flex justify-end pr-2">
      <div className="flex -space-x-3 pr-3">
        {displayedUsers.map((user, index) => (
          <Tooltip title={user.name}>
            {user.photo ? (
              <EmployeeThumb
                image={user.photo}
                name={user.name}
                addClass="lg:w-10 lg:h-10 2xl:w-12 2xl:h-12"
              />
            ) : (
              <div
                key={index}
                className="relative inline-flex items-center justify-center w-8 h-8 lg:w-10 lg:h-10 2xl:w-12 2xl:h-12 hover:scale-125 transition-all overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 ring-2 ring-blue-700"
              >
                <span className="font-medium text-gray-600 dark:text-gray-300 cursor-default">
                  {user.name.charAt(0)}
                </span>
              </div>
            )}
          </Tooltip>
        ))}
        {remainingUserCount > 0 && (
          <div className="relative inline-flex items-center justify-center w-8 h-8 lg:w-10 lg:h-10 2xl:w-12 2xl:h-12 hover:scale-125 transition-all overflow-hidden bg-blue-50 rounded-full ring-2 ring-blue-700">
            <span className="text-blue-700 font-bold cursor-default">
              +{remainingUserCount}
            </span>
          </div>
        )}
      </div>
      <div
        className="flex items-center cursor-pointer pl-3 border-l border-slate-800 dark:border-gray-300"
        onClick={() => viewDetail(users)}
      >
        <Tooltip title={"Gebruikers toevoegen"}>
          <div className="relative inline-flex items-center justify-center w-8 h-8 lg:w-10 lg:h-10 2xl:w-12 2xl:h-12 hover:scale-125 transition-all overflow-hidden bg-blue-700 hover:bg-blue-600 rounded-full ring-2 ring-blue-700">
            <span className="text-white">
              <Plus size={16} />
            </span>
          </div>
        </Tooltip>
        {/* {remainingUserCount > 0 && (
          <span className="ml-2">
            +{remainingUserCount} gebruiker{remainingUserCount > 1 && "s"}
          </span>
        )} */}
      </div>
    </div>
  );
};

export default ProjectUsers;
