import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Typography from "../../typography/Typography";
import { RootState } from "../../../state/store";
import TextHeader from "../../headers/TextHeader";
import { Form, Button, Radio, UploadFile } from "antd";
import FileUpload from "../../inputs/fileupload/FileUpload";
import BranchSelect from "../../branches/BranchSelect";
import SelectSimcard from "../../../organisms/simcards/SelectSimcard";
import SelectTarif from "../../../organisms/simcards/SelectTarif";
import InputField from "../../inputs/text/InputField";
import SelectBox from "../../inputs/selectbox/SelectBox";
import ToggleSwitch from "../../inputs/toggle/ToggleSwitch";
import dayjs from "dayjs";
import DateInput from "../../inputs/dates/DateInput";
import { t } from "i18next";
import LoadingSpinner from "../../buttons/LoadingSpinner";
import FormButton from "../../buttons/FormButton";
import {
  CompanyModules,
  hasModule,
} from "../../../utils/auth/AuthorizationUtility";

interface props {
  onSubmit: any;
  loading: boolean;
}

const SimOnboardingForm: FC<props> = ({ onSubmit, loading }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [eSim, setESim] = useState(false);
  const [budgetmanager, setBudgetmanager] = useState(false);
  const [transferType, setTransferType] = useState("Nieuwe nummer");
  const [values, setValues] = useState({});

  const project = useSelector(
    (state: RootState) => state.project.currentProject
  );
  const branchState = useSelector((state: RootState) => state.branch);
  const task = useSelector((state: RootState) => state.aside.data.task);
  const modules = useSelector((state: RootState) => state.auth.modules);

  useEffect(() => {
    if (values.transferType === "Nieuwe nummer") {
      form.setFieldsValue({
        operator: undefined,
        subscriptionType: undefined,
      });
      setValues((prevValues) => ({
        ...prevValues,
        operator: undefined,
        subscriptionType: undefined,
      }));
    }
  }, [values.transferType, form]);

  const handleValuesChange = (_: any, allValues: any) => {
    setValues(allValues);
  };

  const handleFileChange = (updatedFileList: UploadFile[]) => {
    setFileList(updatedFileList);
  };

  const providerOptions = [
    { value: "Proximus", label: "Proximus" },
    { value: "Orange", label: "Orange" },
    { value: "Base", label: "Base" },
    { value: "Telenet", label: "Telenet" },
    { value: "Mobile Vikings", label: "Mobile Vikings" },
    { value: "Andere", label: "Andere" },
  ];

  const handleBranchSelect = (value: number) => {
    const selectedBranch = branchState.branches.find(
      (branch) => branch.ves_id === value
    );
    form.setFieldsValue({ branch: selectedBranch });
  };

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({ base64String: reader.result.split(",")[1], name: file.name });

      reader.onerror = (error) => reject(error);
    });

  const handleSubmit = async (values: any) => {
    const formData = new FormData();

    const base64Promises = fileList.map((file) => fileToBase64(file));
    const base64Strings = await Promise.all(base64Promises);

    base64Strings.forEach(({ base64String, name }) => {
      formData.append("files[]", base64String);
      formData.append("fileNames[]", name);
    });

    formData.append("branch", JSON.stringify(values.branch));

    formData.append(`simcards[sim]`, values.sim);
    formData.append(`simcards[tarif]`, values.tarif.searchLabel);
    formData.append(`simcards[tarifId]`, values.tarif.value);

    formData.append("date", values.date.format("DD-MM-YYYY"));
    formData.append("isESim", values.isESim ? true : false);
    formData.append("isBudgetManager", values.isBudgetManager ? true : false);
    formData.append("user_id", project ? project.metadata[0].value : "");
    formData.append("transferType", values.transferType);

    if (values.transferType === "Overdracht nummer") {
      formData.append("gsm_number", values.gsmNumber);
      formData.append("operator", values.operator);
      formData.append("subscription_type", values.subscriptionType);
    }

    onSubmit(formData);
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={{
        esim: false,
        budgetmanager: false,
        date: dayjs(new Date()),
        transferType: "Nieuwe nummer",
        subscriptionType: "Herlaadkaart",
      }}
      onValuesChange={handleValuesChange}
      layout="vertical"
      className={loading ? "animate-pulse" : ""}
    >
      <TextHeader
        title="Simkaarten"
        subtitle={"Nieuwe nummer of nummer overdragen"}
      />
      <Form.Item
        label="Type overdracht"
        name="transferType"
        // initialValue="Nieuwe nummer"
        className="mt-5"
      >
        <Radio.Group
          onChange={(event) => {
            form.setFieldsValue({ transferType: event.target.value });
          }}
        >
          <Radio value={"Nieuwe nummer"}>{t("Nieuwe nummer")}</Radio>
          <Radio value={"Overdracht nummer"}>{t("Overdracht nummer")}</Radio>
        </Radio.Group>
      </Form.Item>
      {values.transferType === "Overdracht nummer" ? (
        <>
          <div className="mb-1">
            <Typography tag={"h1"} type={"semibold"}>
              Voor we beginnen
            </Typography>
            <Typography tag={"p"} type={"label"}>
              Voor de activatie zijn enkele documenten nodig:
            </Typography>
            <ul className="mt-2">
              <li className="list-disc ml-5">
                <Typography tag={"p"} type={"default"}>
                  Download alvast de LOA als het nummer wordt overgedragen van
                  een andere operator en neem een kopie van de identiteitskaart
                  van de huidige titularis.
                </Typography>
              </li>
              {values.operator === "Proximus" &&
              values.subscriptionType === "Abonnement" ? (
                <li className="list-disc ml-5">
                  <Typography tag={"p"} type={"default"}>
                    Download het overdrachtsformulier van Proximus voor een
                    overdracht binnen Proximus.
                  </Typography>
                </li>
              ) : (
                ""
              )}
            </ul>
            <div className="mt-3 mb-5 flex gap-2">
              <Button
                href="../../../LOA_mobile_NL.pdf"
                download="LOA_mobile_NL.pdf"
              >
                Download Letter Of Authorization
              </Button>
              {values.operator === "Proximus" &&
              values.subscriptionType === "Abonnement" ? (
                <Button
                  href="../../../Overdrachtsdocument_Proximus_NL.pdf"
                  download="Overdrachtsdocument_Proximus_NL.pdf"
                >
                  Download overdrachtsformulier Proximus
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
          <Form.Item
            label="Huidige GSM-nummer"
            name="gsmNumber"
            rules={[
              {
                required:
                  values.transferType === "Overdracht nummer" ? true : false,
                message: "Voer een geldig GSM-nummer in.",
              },
            ]}
          >
            <InputField type="text" name="gsmNumber" placeholder="GSM-nummer" />
          </Form.Item>
          <Form.Item
            label="Huidige operator"
            name="operator"
            // initialValue="Proximus"
            rules={[
              {
                required:
                  values.transferType === "Overdracht nummer" ? true : false,
                message: "Selecteer een operator.",
              },
            ]}
          >
            <SelectBox
              search={true}
              options={providerOptions}
              onChange={(event, option) =>
                form.setFieldsValue({ operator: option.value })
              }
            />
          </Form.Item>
          <Form.Item
            label="Maak je gebruik van een herlaadkaart of een abonnement?"
            name="subscriptionType"
            // initialValue="Herlaadkaart"
          >
            <Radio.Group
              onChange={(event) =>
                form.setFieldsValue({ subscriptionType: event.target.value })
              }
            >
              <Radio value={"Herlaadkaart"}>{t("Herlaadkaart")}</Radio>
              <Radio value={"Abonnement"}>{t("Abonnement")}</Radio>
            </Radio.Group>
          </Form.Item>
        </>
      ) : (
        ""
      )}

      <div className="mb-2 grid grid-cols-5 gap-3">
        <div className="col-span-1">
          <Form.Item name="isESim" valuePropName="checked">
            <ToggleSwitch
              label="eSIM"
              onChange={(event) =>
                form.setFieldsValue({ isESim: event.target.checked })
              }
              name="isESim"
            />
          </Form.Item>
        </div>
        {hasModule(modules, CompanyModules.BUDGETMANAGER) && (
          <div className="col-span-1">
            <Form.Item name="isBudgetManager" valuePropName="checked">
              <ToggleSwitch
                label="Budgetmanager"
                checked={form.getFieldValue("isBudgetManager")}
                onChange={(event) =>
                  form.setFieldsValue({ isBudgetManager: event.target.checked })
                }
                name="isBudgetManager"
              />
            </Form.Item>
          </div>
        )}
      </div>
      {values.isBudgetManager && (
        <div>
          <ul className="mt-2">
            <li className="list-disc ml-5">
              <Typography tag={"p"} type={"default"}>
                U moet het intekeningsdocument onderaan uploaden.
              </Typography>
            </li>
          </ul>
          <div className="mt-2 mb-5 flex gap-2">
            <Button
              href="../../../BudgetManager_NL.pdf"
              download="IntekeningsdocumentBudgetmanager.pdf"
            >
              Download intekeningsdocument budgetmanager
            </Button>
          </div>
        </div>
      )}
      <div className="grid grid-cols-2 gap-x-2">
        <div className="col-span-2">
          <Form.Item
            label="Simkaart selecteren of ingeven"
            name="sim"
            rules={[
              {
                required: true,
                message: "Selecteer een simkaart.",
              },
            ]}
          >
            <SelectSimcard
              onSelect={(value, option) =>
                form.setFieldsValue({ sim: option.value })
              }
              status={"inactive"}
              error={undefined}
              disabled={values.eSim}
            />
          </Form.Item>
        </div>
        <div className="col-span-2">
          <Form.Item
            label="Tariefplan selecteren"
            name="tarif"
            rules={[
              {
                required: true,
                message: "Selecteer een tariefplan.",
              },
            ]}
          >
            <SelectTarif
              onSelect={(value, option) => {
                form.setFieldsValue({
                  tarif: option,
                });
              }}
              defaultValue={undefined}
              type={"voice"}
            />
          </Form.Item>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <Form.Item
          label="Vestiging"
          name="branch"
          rules={[
            {
              required: true,
              message: "Selecteer een vestiging.",
            },
          ]}
        >
          <BranchSelect
            handleSelect={handleBranchSelect}
            defaultValue={"Selecteer vestiging"}
          />
        </Form.Item>
        <Form.Item
          label="Uitvoeringsdatum"
          name="date"
          rules={[
            {
              required: true,
              message: "Selecteer een uitvoeringsdatum.",
            },
          ]}
        >
          <DateInput
            onChange={(date) => form.setFieldsValue({ date: date })}
            value={values.date}
            endDate={project ? project.proj_deadline : ""}
          />
        </Form.Item>
      </div>
      <div className="mb-5">
        <FileUpload
          fileTypes={["application/pdf"]}
          fileList={fileList}
          onChange={handleFileChange}
        />
      </div>
      <div className="mt-4">
        <FormButton form={true} text="Indienen" />
      </div>
      {loading ? <LoadingSpinner /> : ""}
    </Form>
  );
};

export default SimOnboardingForm;
